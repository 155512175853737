import { Global, css } from "@emotion/react";

const GlobalStyle = () => {
  return (
    <>
      <Global
        styles={css`
          html,
          body {
            padding: 0;
            margin: 0;
            color: #1a1a1a;
            font-family: "Noto Sans JP", -apple-system, BlinkMacSystemFont,
              Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
              Helvetica Neue, sans-serif;
            font-size: 1px;
            @media (min-width: 769px) {
              font-size: calc(14 / 16);
            }
            line-height: calc(24 / 14);
          }

          a {
            color: inherit;
            text-decoration: none;
          }

          * {
            box-sizing: border-box;
          }

          .btn {
            @apply m-2;
          }

          P {
            margin: 8rem 0;
          }

          h1 {
            margin-top: 24rem;
            margin-bottom: 16rem;
            font-size: 28rem;
            font-weight: 700;
            line-height: 41rem;
            text-align: center;
            @media (min-width: 769px) {
              margin-top: 60rem;
              margin-bottom: 36rem;
              font-size: 48rem;
              line-height: 70rem;
            }
          }

          h2 {
            margin-bottom: 20rem;
            font-size: 20rem;
            line-height: 24rem;
            @media (min-width: 769px) {
              margin-bottom: 32rem;
              font-size: 32rem;
              line-height: 32rem;
            }
          }

          h3 {
            font-size: 14rem;
            line-height: 1;
            @media (min-width: 769px) {
              font-size: 20rem;
              line-height: 28.8rem;
            }
          }

          table {
            width: 100%;
            border: 1rem solid #eaeaea;
            line-height: 1;
          }

          tr:not(:last-child) > th {
            box-sizing: border-box;
            border-bottom: 1rem solid #eaeaea;
          }

          tr:not(:last-child) > td {
            box-sizing: border-box;
            border-bottom: 1rem solid #eaeaea;
          }

          th,
          td {
            padding: 24rem 0rem 24rem 12rem;
            text-align: left;
          }

          td {
            font-size: 16rem;
            padding: 24rem;
          }

          th {
            font-size: 16rem;
            font-style: normal;
            font-weight: bold;
          }

          ol {
            padding-left: 24rem;
          }
        `}
      ></Global>
    </>
  );
};

export default GlobalStyle;
