import { css } from "@emotion/css";
import { ReactNode, VFC } from "react";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import useIsMobile from "./hooks/useIsMobile";

const Footer = () => {
  const isMobile = useIsMobile();
  return isMobile ? (
    <footer
      className={css`
        background: #00873a;
        color: white;
        font-size: 12rem;
        line-height: 17rem;
      `}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          padding-top: 40rem;
          padding-left: 24rem;
        `}
      >
        <div
          className={css`
            display: flex;
            align-items: center;
          `}
        >
          <span
            className={css`
              font-size: 32rem;
              font-weight: bold;
              line-height: 32rem;
            `}
          >
            株式会社クリーネ
          </span>
        </div>
        <div
          className={css`
            padding: 0;
            margin: 16rem 0 36rem;
          `}
        >
          <div
            className={css`
              margin-bottom: 4px;
            `}
          >
            〒110-0003
          </div>
          <div>東京都台東区根岸5-12-10 芹澤ビル2F</div>
        </div>
        <div
          className={css`
            display: grid;
            grid-template-columns: 2fr 1fr 1fr;
          `}
        >
          <div
            className={css`
              margin-right: 40rem;
            `}
          >
            <LinkButton href="/">ホーム</LinkButton>
            <LinkButton href="/leaveService">退去立ち会い代行事業</LinkButton>
            <LinkButton href="/restorationService">原状回復工事事業</LinkButton>
            <LinkButton href="/antibacterialService">抗菌消臭事業</LinkButton>
            <LinkButton href="/about">会社案内</LinkButton>
            <LinkButton href="/contact">お問い合わせ</LinkButton>
            <LinkButton href="/privacy">個人情報保護方針</LinkButton>
          </div>
          <div>
            <img
              className={css`
                margin-right: 20rem;
              `}
              width="50"
              height="50"
              alt=""
              src="/images/privacy.png"
              onClick={() => {
                window.location.href = "https://privacymark.jp";
              }}
            ></img>
            <img width="50" height="50" alt="" src="/images/nintei.png"></img>
            <img
              width="135"
              height="50"
              alt=""
              src="/images/it.jpg"
              onClick={() => {
                window.location.href =
                  "https://www.ropes.co.jp/lp/2022ropes-it/";
              }}
            ></img>
            <img
              width="135"
              height="50"
              alt=""
              src="/images/saikotiku.jpg"
              onClick={() => {
                window.location.href =
                  "https://www.ropes.co.jp/lp/ropes-rebuild/";
              }}
            ></img>
          </div>
        </div>
        <div
          className={css`
            text-align: center;
            margin-bottom: 8rem;
          `}
        >
          © CLEANE, Inc
        </div>
      </div>
    </footer>
  ) : (
    <footer
      className={css`
        background: #00873a;
        color: white;
        font-size: 12rem;
        line-height: 17rem;
      `}
    >
      <div
        className={css`
          display: flex;
          padding: 80rem 80rem;
        `}
      >
        <div>
          <div
            className={css`
              font-size: 32rem;
              font-weight: bold;
              line-height: 32rem;
            `}
          >
            株式会社クリーネ
          </div>
          <div
            className={css`
              margin: 24px 0;
            `}
          >
            <div
              className={css`
                margin-bottom: 4px;
              `}
            >
              〒110-0003
            </div>
            <div>東京都台東区根岸5-12-10 芹澤ビル2F</div>
          </div>
          <Button
            to=""
            url="https://zoom.us/j/4053268641?pwd=Smx6clFNdC9oM0xhTmdOYmI4ZEovUT09"
          >
            オンライン面談
          </Button>
        </div>
        <div
          className={css`
            margin-left: 80rem;
            display: grid;
            grid-template-columns: 200px 270px;
          `}
        >
          <div>
            <LinkButton href="/">ホーム</LinkButton>
            <LinkButton href="/leaveService">退去立ち会い代行事業</LinkButton>
            <LinkButton href="/restorationService">原状回復工事事業</LinkButton>
            <LinkButton href="/antibacterialService">抗菌消臭事業</LinkButton>
            <LinkButton href="/about">会社案内</LinkButton>
            <LinkButton href="/contact">お問い合わせ</LinkButton>
            <LinkButton href="/privacy">個人情報保護方針</LinkButton>
          </div>
          <div>
            <img
              width="100"
              height="100"
              alt=""
              src="/images/privacy.png"
              onClick={() => {
                window.location.href = "https://privacymark.jp";
              }}
            ></img>
            <img width="100" height="100" alt="" src="/images/nintei.png"></img>
            <img
              width="270"
              height="100"
              alt=""
              src="/images/it.jpg"
              onClick={() => {
                window.location.href =
                  "https://www.ropes.co.jp/lp/2022ropes-it/";
              }}
            ></img>
            <img
              width="270"
              height="100"
              alt=""
              src="/images/saikotiku.jpg"
              onClick={() => {
                window.location.href =
                  "https://www.ropes.co.jp/lp/ropes-rebuild/";
              }}
            ></img>
          </div>
        </div>
      </div>
      <div
        className={css`
          text-align: center;
          padding-bottom: 10px;
        `}
      >
        © CLEANE, Inc
      </div>
    </footer>
  );
};

const LinkButton: VFC<{ href: string; children: ReactNode }> = ({
  href,
  children,
}) => {
  return (
    <Link to={href}>
      <p
        className={css`
          cursor: pointer;
        `}
      >
        {children}
      </p>
    </Link>
  );
};

export default Footer;
