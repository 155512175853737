import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GlobalStyle from "./components/GlobalStyle";
import Layout from "./components/Layout";
import ScrollToTop from "./components/ScrollToTop";

const Home = lazy(() => import("./pages/home"));
const About = lazy(() => import("./pages/about"));
const Contact = lazy(() => import("./pages/contact"));
const LeaveService = lazy(() => import("./pages/leaveService"));
const RestorationService = lazy(() => import("./pages/restorationService"));
const AntibacterialService = lazy(() => import("./pages/antibacterialService"));
const Achievement = lazy(() => import("./pages/achievement"));
const Privacy = lazy(() => import("./pages/privacy"));
const ContactPrivacy = lazy(() => import("./pages/contactPrivacy"));

function App() {
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <Router>
        <ScrollToTop></ScrollToTop>
        <Layout>
          <Suspense fallback={<></>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/leaveService" element={<LeaveService />} />
              <Route
                path="/restorationService"
                element={<RestorationService />}
              />
              <Route
                path="/antibacterialService"
                element={<AntibacterialService />}
              />
              <Route path="/achievement" element={<Achievement />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/contactPrivacy" element={<ContactPrivacy />} />
            </Routes>
          </Suspense>
        </Layout>
      </Router>
    </>
  );
}

export default App;
