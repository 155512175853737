import { css } from "@emotion/css";
import clsx from "clsx";
import { ComponentProps, HTMLAttributes, VFC } from "react";
import { Link } from "react-router-dom";

const Button: VFC<
  HTMLAttributes<HTMLDivElement> & {
    to: ComponentProps<typeof Link>["to"];
    url?: string;
  }
> = ({ children, to, url, ...props }) => {
  if (url) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <span
          {...props}
          className={clsx(
            props.className,
            css`
              position: relative;
              display: inline-block;
              width: 230rem;
              box-sizing: border-box;
              padding: 16rem;
              border: 1rem solid #1a1a1a;
              margin-top: 32rem;
              background: #ffffff;
              cursor: pointer;
              font-size: 16rem;
              line-height: 23rem;
              text-align: left;

              &::before {
                position: absolute;
                top: 0;
                right: 0;
                left: auto;
                width: 0;
                height: 100%;
                background-color: #00873a;
                content: " ";
                transition-duration: 0.5s;
                transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
              }
              &:hover:before {
                right: auto;
                left: 0;
                width: 100%;
              }

              &::after {
                position: absolute;
                top: 0;
                right: 16rem;
                bottom: 0;
                width: 18rem;
                height: 9rem;
                margin: auto;
                background: url("/images/no-arrow.svg") no-repeat;
                content: " ";
              }

              &:hover::after {
                background: url("/images/arrow.svg") no-repeat;
              }
            `
          )}
        >
          <span
            className={css`
              position: relative;
              font-weight: bold;
              color: black;
            `}
          >
            {children}
          </span>
        </span>
      </a>
    );
  }
  return (
    <Link to={to}>
      <span
        {...props}
        className={clsx(
          props.className,
          css`
            position: relative;
            display: inline-block;
            width: 230rem;
            box-sizing: border-box;
            padding: 16rem;
            border: 1rem solid #1a1a1a;
            margin-top: 32rem;
            background: #ffffff;
            cursor: pointer;
            font-size: 16rem;
            line-height: 23rem;
            text-align: left;

            &::before {
              position: absolute;
              top: 0;
              right: 0;
              left: auto;
              width: 0;
              height: 100%;
              background-color: #00873a;
              content: " ";
              transition-duration: 0.5s;
              transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
            }
            &:hover:before {
              right: auto;
              left: 0;
              width: 100%;
            }

            &::after {
              position: absolute;
              top: 0;
              right: 16rem;
              bottom: 0;
              width: 18rem;
              height: 9rem;
              margin: auto;
              background: url("/images/no-arrow.svg") no-repeat;
              content: " ";
            }

            &:hover::after {
              background: url("/images/arrow.svg") no-repeat;
            }
          `
        )}
      >
        <span
          className={css`
            position: relative;
            font-weight: bold;
            color: black;
          `}
        >
          {children}
        </span>
      </span>
    </Link>
  );
};

export default Button;
