import { css } from "@emotion/css";
import React, { VFC } from "react";
import { Link } from "react-router-dom";

const HeaderButton: VFC<{ title: string; href: string }> = ({
  title,
  href,
}) => {
  return (
    <Link to={href}>
      <span
        className={css`
          position: relative;
          padding: 2rem;
          cursor: pointer;
          color: #ffffff;
          font-size: 16rem;
          font-weight: bold;
          line-height: 16rem;
          white-space: nowrap;
        `}
      >
        <span
          className={css`
            position: relative;
          `}
        >
          {title}
        </span>
      </span>
    </Link>
  );
};

export default HeaderButton;
