/* eslint-disable jsx-a11y/alt-text */
import { css } from "@emotion/css";
import { Link } from "react-router-dom";
import useIsMobile from "../components/hooks/useIsMobile";

const Logo = () => {
  const isMobile = useIsMobile();
  return (
    <Link to="/">
      <div
        className={css`
          display: flex;
          align-items: center;
        `}
      >
        <img
          className={css`
            margin-right: 8px;
          `}
          width="50"
          height="50"
          src="/images/logo.png"
        ></img>
        {isMobile ? (
          <img
            className={css`
              margin-right: 8px;
            `}
            width="150"
            height="15"
            src="/images/cleane_original_color.png"
          ></img>
        ) : (
          <img
            className={css`
              margin-right: 8px;
            `}
            width="150"
            height="15"
            src="/images/cleane_white_color.png"
          ></img>
        )}
      </div>
    </Link>
  );
};

export default Logo;
