import { css } from "@emotion/css";
import React, { ReactNode, VFC } from "react";
import Footer from "./Footer";
import Header from "./Header";
import useIsMobile from "./hooks/useIsMobile";

const Layout: VFC<{ children: ReactNode }> = ({ children }) => {
  const isMobile = useIsMobile();
  return (
    <div
      className={css`
        font-size: 16rem;
        ${isMobile &&
        css`
          font-size: 14rem;
        `}
      `}
    >
      <div
        className={css`
          min-height: calc(100vh - 362rem);
        `}
      >
        <Header></Header>
        <main>{children}</main>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Layout;
