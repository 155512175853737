import { css } from "@emotion/css";
import { CSSProperties, ReactNode, useState, VFC } from "react";
import { Transition } from "react-transition-group";
import CloseIcon from "../icons/CloseIcon";
import HeaderButton from "./HeaderButton";
import HamburgerIcon from "../icons/HamburgerIcon";
import useIsMobile from "./hooks/useIsMobile";
import Button from "../components/Button";
import Logo from "./HeaderLogo";
import { Link } from "react-router-dom";

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles: { [key: string]: CSSProperties } = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
};

const Header = () => {
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  return isMobile ? (
    <header
      className={css`
        display: flex;
        height: 64rem;
        align-items: center;
        justify-content: space-between;
        margin: 0 16rem;
      `}
    >
      <Logo></Logo>
      <HamburgerIcon
        className={css`
          cursor: pointer;
        `}
        onClick={() => {
          setIsOpen(true);
        }}
      ></HamburgerIcon>
      <Transition in={isOpen} timeout={duration} appear>
        {(state: string) => (
          <div
            className={css`
              position: fixed;
              z-index: 100;
              top: 0;
              left: 0;
              width: 100vw;
              height: 100vh;
              background: rgba(0, 0, 0, 0.9);
            `}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
              visibility: state === "exited" ? "hidden" : undefined,
            }}
          >
            <div
              className={css`
                display: flex;
                height: 64rem;
                align-items: center;
                justify-content: space-between;
                margin: 0 16rem;
              `}
            >
              <Logo></Logo>
              <CloseIcon
                className={css`
                  cursor: pointer;
                `}
                onClick={() => setIsOpen(false)}
              ></CloseIcon>
            </div>
            <div
              className={css`
                margin-top: 40rem;
                margin-left: 40rem;
                color: #ffffff;
              `}
            >
              <div
                className={css`
                  margin-top: 10rem;
                `}
              >
                <PageLink to="/" onClick={onClose}>
                  ホーム
                </PageLink>
              </div>
              <div
                className={css`
                  margin-top: 10rem;
                `}
              >
                <PageLink to="/leaveService" onClick={onClose}>
                  退去立ち会い代行事業
                </PageLink>
              </div>
              <div
                className={css`
                  margin-top: 10rem;
                `}
              >
                <PageLink to="/restorationService" onClick={onClose}>
                  原状回復工事事業
                </PageLink>
              </div>
              <div
                className={css`
                  margin-top: 10rem;
                `}
              >
                <PageLink to="/antibacterialService" onClick={onClose}>
                  抗菌消臭事業
                </PageLink>
              </div>
              <div
                className={css`
                  margin-top: 10rem;
                `}
              >
                <PageLink to="/about" onClick={onClose}>
                  会社案内
                </PageLink>
              </div>
              <div
                className={css`
                  margin-top: 10rem;
                `}
              >
                <PageLink to="/contact" onClick={onClose}>
                  お問い合わせ
                </PageLink>
              </div>
              <Button
                to=""
                url="https://zoom.us/j/4053268641?pwd=Smx6clFNdC9oM0xhTmdOYmI4ZEovUT09"
              >
                オンライン面談
              </Button>
            </div>
          </div>
        )}
      </Transition>
    </header>
  ) : (
    <header
      className={css`
        display: flex;
        height: 96rem;
        align-items: center;
        padding: 0 30rem;
        background: #00873a;
      `}
    >
      <span
        className={css`
          flex: 1;
        `}
      >
        <Logo></Logo>
      </span>
      <span
        className={css`
          display: flex;
          > * {
            margin-left: 30rem;
          }
        `}
      >
        <HeaderButton title="ホーム" href="/"></HeaderButton>
        <HeaderButton
          title="退去立ち会い代行事業"
          href="/leaveService"
        ></HeaderButton>
        <HeaderButton
          title="原状回復工事事業"
          href="/restorationService"
        ></HeaderButton>
        <HeaderButton
          title="抗菌消臭事業"
          href="/antibacterialService"
        ></HeaderButton>
        <HeaderButton title="会社案内" href="/about"></HeaderButton>
        <HeaderButton title="お問い合わせ" href="/contact"></HeaderButton>
      </span>
    </header>
  );
};

export default Header;

const PageLink: VFC<{
  to: string;
  children: ReactNode;
  onClick: () => void;
}> = ({ to, onClick, children }) => {
  return (
    <div
      className={css`
        display: flex;
        align-items: center;
      `}
    >
      <Link
        onClick={onClick}
        className={css`
          font-size: 16rem;
          font-weight: bold;
          line-height: 23rem;
        `}
        to={to}
      >
        {children}
      </Link>
    </div>
  );
};
